import styled, { css } from "styled-components"

export default styled.textarea`
  border: 1px solid #bebabe;
  margin-top: 0.5rem;
  padding: 1rem;
  
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.pink};
    box-shadow: 0 0 8px -2px rgba(239,125,186,1);
  }
  
    ${({ error }) => error && css`
      border: 1px solid #dc3545;
      :focus {
        box-shadow: 0 0 8px -2px #dc3545;
      }
    `}
  
  ${({ success }) => success && css`
    border: 1px solid #00b200;
  `}
`;