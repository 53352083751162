import React from "react"
import HeaderSmall from "../atoms/header-small"
import ContactParagraph from "../atoms/contact-paragraph-small"
import styled from "styled-components"
import data from "../../data"
import ActionParagraphLink from "../atoms/action-paragraph-link"

const ContactBottomWrapper = styled.div`
  margin-top: 30px;
`

export default () => (
  <section>
    <HeaderSmall>{data.company.name}</HeaderSmall>
    <ContactParagraph>{data.company.street}</ContactParagraph>
    <ContactParagraph>{data.company.city}</ContactParagraph>
    <ContactParagraph>Tel.: {data.company.tel}</ContactParagraph>
    <ContactParagraph>Email: {data.company.emial}</ContactParagraph>
    <ActionParagraphLink to="/nasze-placowki">
      Pozostałe placówki
    </ActionParagraphLink>
    <ContactBottomWrapper>
      <ContactParagraph>NIP: {data.company.nip}</ContactParagraph>
      <ContactParagraph>REGON: {data.company.regon}</ContactParagraph>
    </ContactBottomWrapper>
    <ContactBottomWrapper>
      <HeaderSmall>Konto Bankowe</HeaderSmall>
      <ContactParagraph>{data.company.bankName}</ContactParagraph>
      <ContactParagraph>{data.company.accountNumber}</ContactParagraph>
    </ContactBottomWrapper>
  </section>
)
