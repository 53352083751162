import React from "react"
import Layout from "../components/organisms/layout"
import Seo from "../components/seo"
import PageBackground from "../components/atoms/page-background"
import ContactPage from "../components/organisms/contact-page"
import { graphql } from "gatsby"

const ContactPageWrapper = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <Seo title="Kontakt" />
    <PageBackground img={data.placeholderImage.childImageSharp.fluid} />
    <ContactPage />
  </Layout>
)

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "auditorium-benches-chairs.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 4608) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default ContactPageWrapper
