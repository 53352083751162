import styled from "styled-components"

export default styled.section`
  width: 100%;
  margin-top: 2vh;
  border-left: 2px solid #dc3545;
  padding: 10px 15px;
  position: relative;
  overflow: hidden;
  background: #F0ECF170;
  
  ${
  ({ success }) => success && "border-left: 2px solid #00b200;fill: #00b200;"
  };
  
  ${
  ({ error }) => error && "border-left: 2px solid #dc3545;fill: #dc3545;"
  };

  h4 {
    font-weight: 500;
    font-size: 2rem;
    margin: 0;
  }
  
  p {
    margin-top: 2vh;
    margin-bottom: 0;
  }
  
  svg {
    height: 100%;
    position: absolute;
    top: -23%;
    right: -8%;
    opacity: .4;
  }
`