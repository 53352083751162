import React from "react"
import styled from "styled-components"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heding from "../atoms/newsletter-heading"
import ContactDetails from "../molecules/contact-details-all"
import GoogleMap from "../molecules/google-map"
import ContactForm from "../molecules/contact-form"
import Policy from "../molecules/policy"
import Wrapper from "../atoms/page-wrapper"

const MapWrapper = styled.div`
  height: 400px;
`

const Grid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 4fr 6fr;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
    grid-row-gap: 75px;
  }
`

const BottomWrapper = styled.div`
  margin-top: 75px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-row-gap: 75px;
  }
`

const ContactPage = () => (
  <Wrapper>
    <ParagraphItalic>kontakt</ParagraphItalic>
    <Heding>Jak się z nami skontaktować?</Heding>

    <Grid>
      <ContactDetails />
      <MapWrapper>
        <GoogleMap />
      </MapWrapper>
    </Grid>

    <BottomWrapper>
      <ContactForm />
      <Policy />
    </BottomWrapper>
  </Wrapper>
)

export default ContactPage
