import React, { Component, createRef } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import Heading from "../atoms/newsletter-heading"
import axios from "axios"
import { endpoint } from "../../api"
import form from "../molecules/contact-form-render"
import data from "../../data"
import StatusContainer from '../atoms/contact-status'

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email("Nieprawidłowy adres email.")
    .required("Pole wymagane."),
  title: Yup.string()
    .min(3, "Temat musi zawierać co najmniej 3 znaki.")
    .max(60, "Temat może zawierać maksymalnie 60 znaków.")
    .required("Pole wymagane."),
  content: Yup.string()
    .min(10, "Treść wiadomości musi zawierać co najmniej 10 znaków ")
    .max(1500, "Treść wiadomości może zawierać maksymalnie 1500 znaków.")
    .required("Pole wymagane."),
  recaptcha: Yup.string()
    .required("Musisz potwierdzić, że jesteś człowiekiem."),
})

class ContactForm extends Component {

  state = {
    status: "form",
    errorRef: createRef(),
  }

  componentDidMount() {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }

  onSubmit = (values, actions) => {
    axios.post(
      endpoint.enrollSubmit,
      { ...values, type: "contact" },
    )
      .then((response) => {
        if (response.data === "ok") {
          actions.resetForm()
          this.formSuccess()
        } else {
          this.formFail()
        }
      })
      .catch(() => {
        this.formFail()
      })
  }

  formFail = () => {
    this.setState(
      { status: "fail" },
      () => window.scrollTo(0, this.state.errorRef.current.offsetTop),
    )
  }

  formSuccess = () => {
    this.setState(
      { status: "success" },
    () => window.scrollTo(0, this.state.errorRef.current.offsetTop)
    )
  }

  embedForm = () => (
    <Formik
      onSubmit={this.onSubmit}
      validationSchema={formSchema}
      render={form}
      initialValues={{
        title: "",
        email: "",
        content: "",
        recaptcha: "",
      }}
    />
  )

  handleStatus = () => {

    if (this.state.status === "form") {
      return this.embedForm()
    }

    if (this.state.status === "fail") {
      return (
        <>
          {this.embedForm()}
          <StatusContainer ref={this.state.errorRef} error>
            <h4>Błąd</h4>
            <p>Niestety wystąpił problem przy wysyłaniu formularza. Prosimy spróbować raz jeszcze albo wysłać wiadomość
              bezpośrednio na adres {data.company.emial}.</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 24 24"}>
              <path
                d="M11 15h2v2h-2zm0-8h2v6h-2zm1-5C6.47 2 2 6.48 2 12s4.47 10 10 10A10.01 10.01 0 0 0 22 12 10.01 10.01 0 0 0 11.99 2zM12 20a8 8 0 1 1 0-16 8 8 0 1 1 0 16z"/>
            </svg>
          </StatusContainer>
        </>
      )
    }

    if (this.state.status === "success") {
      return (
        <>
          <StatusContainer ref={this.state.errorRef} success>
            <h4>Wiadomość wysłana</h4>
            <p>Twoja wiadomość została wysłana. Wkrótce ktoś się odezwie w tej sprawie do ciebie. Dziękujemy za
              kontakt!</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 24 24"}>
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
            </svg>
          </StatusContainer>
        </>
      )
    }
  }

  render = () => (
    <section>
      <Heading>Formularz kontaktowy</Heading>
      {this.handleStatus()}
    </section>
  )
}

export default ContactForm