import FormLabel from "../atoms/enroll-form-label"
import FormLabelText from "../atoms/enroll-form-label-text"
import FormInput from "../atoms/enroll-form-input"
import errorLabel from "./enroll-form-error"
import FormTextArea from "../atoms/enroll-form-textarea"
import Recaptcha from "react-recaptcha"
import LinkStyled from "../atoms/action-paragraph-link"
import Submit from "../atoms/button"
import React from "react"
import styled from "styled-components"

const Form = styled.form`
  margin-top: 20px;
`

export default props => (
  <Form onSubmit={props.handleSubmit}>
    <FormLabel>
      <FormLabelText>Twój adres e-mail <span>*</span></FormLabelText>
      <FormInput
        placeholder={"jan.kowalski@poczta.pl"}
        type={"text"}
        name={"email"}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.email}
        required={true}
        error={props.errors.email && props.touched.email}
        success={!props.errors.email && props.values.email}
      />
      {
        props.errors.email &&
        props.touched.email
        && errorLabel(props.errors.email)
      }
    </FormLabel>

    <FormLabel>
      <FormLabelText>Tytuł wiadomości <span>*</span></FormLabelText>
      <FormInput
        placeholder={"Pytanie w sprawie kursu"}
        type={"text"}
        name={"title"}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.title}
        required={true}
        error={props.errors.title && props.touched.title}
        success={!props.errors.title && props.values.title}
      />
      {
        props.errors.title &&
        props.touched.title
        && errorLabel(props.errors.title)
      }
    </FormLabel>

    <FormLabel>
      <FormLabelText>Treść wiadomości <span>*</span></FormLabelText>
      <FormTextArea
        placeholder={"Tutaj można zostawić uwagi."}
        name={"content"}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.content}
        rows={5}
        required={true}
        error={props.errors.content && props.touched.content}
        success={!props.errors.content && props.values.content}
      />
      {
        props.errors.content &&
        props.touched.content
        && errorLabel(props.errors.content)
      }
    </FormLabel>

    <FormLabel>
      <Recaptcha
        sitekey={"6LfIjaYUAAAAAE2HkOdskilgAP6iWf5UhTHqXa69"}
        render={"explicit"}
        onloadCallback={() => 1}
        verifyCallback={(response) => {
          props.setFieldValue("recaptcha", response)
        }}
        expiredCallback={() => {
          props.setFieldValue("recaptcha", "")
          props.setFieldError("recaptcha", "Weryfikacja wygasła.")
        }}
      />
      {
        props.errors.recaptcha &&
        props.touched.recaptcha
        && errorLabel(props.errors.recaptcha)
      }
    </FormLabel>
    <p>Wysyłając wiadomość za pośrednictwem powyższego formularza, zgadzasz się na przetwarzanie swoich danych o których
      mowa jest na <LinkStyled to={"/polityka-prywatnosci"}>tej stronie.</LinkStyled></p>
    <Submit
      type="submit"
      width="100% !important"
      disabled={!props.isValid}
    >wyślij</Submit>
  </Form>
)