import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`

const Map = () => (
  <Wrapper>
    <Iframe
      title="Mapa pokazująca dojazd"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d635.77853007311!2d18.619044091807883!3d50.4017107560486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47112504839965a1%3A0x9fe672cbd552d4d4!2sStudio+Edukacji+Amuzo+Aleksandra+Sydor!5e0!3m2!1spl!2spl!4v1564734241675!5m2!1spl!2spl"
      width="300"
      height="300"
      allowFullScreen
    />
  </Wrapper>
)

export default Map
