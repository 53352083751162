import styled from 'styled-components';

const HeaderSmall = styled.h5`
    
    font-size: ${({theme}) => theme.m};
    font-weight: 600;
    margin: 5px 0;
  
`;

export default HeaderSmall;