import React from "react"
import Heading from "../atoms/newsletter-heading"
import Paragraph from "../atoms/paragraph-small"
import ActionParagraph, {
  normalLink as ActionParagraphAnchor,
} from "../atoms/action-paragraph-link"
import oswiadczeniePDF from "../../docs/oswiadczenie.pdf"
import infoPDF from "../../docs/info.pdf"

const Policy = () => (
  <section>
    <Heading>Polityka prywatności</Heading>
    <Paragraph>
      W związku z wejściem w życie Rozporządzenia o Ochronie Danych Osobowych
      (RODO), informujemy o tym, że: Administratorem danych osobowych jest
      Aleksandra Sydor prowadząca działalność gospodarczą pod nazwą ENGLISH
      PRACTICE Aleksandra Sydor siedzibą w Pyskowicach przy ul. Wojska Polskiego
      25, nr NIP 9691541386.
    </Paragraph>
    <Paragraph>
      Oznacza to, że jest podmiotem decydującym o celach i sposobach
      przetwarzania Państwa danych osobowych Łącząca Państwa relacja prawna z
      firmą Amuzo Aleksandra Sydor decyduje o tym w jakich celach są
      przetwarzane Państwa dane osobowe.
    </Paragraph>
    <Paragraph>
      Proszę wszystkich obecnych i nowych klientów o pobranie plików poniżej,
      wydrukowanie i dostarczenie - osobiście przy pierwszym spotkaniu.
    </Paragraph>
    <ul>
      <li>
        <ActionParagraphAnchor
          href={infoPDF}
          title={"Informacja o przetwarzaniu danych osobowych"}
          target="_blank"
        >
          Informacja o przetwarzaniu danych osobowych
        </ActionParagraphAnchor>
      </li>
      <li>
        <ActionParagraphAnchor
          href={oswiadczeniePDF}
          title={"Oświadczenie o wyrażeniu zgody"}
          target="_blank"
        >
          Oświadczenie o wyrażeniu zgody
        </ActionParagraphAnchor>
      </li>
    </ul>
    <Paragraph>
      Informujemy w nim przede wszystkim o: Podstawie prawnej i celu
      przetwarzania danych osobowych Okresie przez jaki będą one przetwarzane i
      kategoriach ich odbiorców Państwa prawach, jako podmiotów danych, czyli
      osób których dane dotyczą
    </Paragraph>
    <Paragraph>
      Pełna treść informacji o przetwarzaniu danych osobowych, znajduje się na{" "}
      <ActionParagraph
        to={"/polityka-prywatnosci"}
        title={"Polityka prywatności"}
      >
        tej stronie
      </ActionParagraph>
      .
    </Paragraph>
  </section>
)

export default Policy
